<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="仓库编号" >
                <el-input v-model="search.cangKuBH" size="small" />
            </x-search-item>
            <x-search-item label="仓库名称">
                <el-select v-model="search.cangKuMC" size="small">
                    <el-option v-for="item in options" :key="item.cangKuMC" :label="item.cangKuMC"
                               :value="item.cangKuMC"></el-option>
                </el-select>
            </x-search-item>

            <x-search-item label="启用状态" >
                <x-selector-one v-model="search.use" size="small" dictType="T_USE"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">
                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>
            </el-popconfirm>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column  prop="cangKuBH" label="仓库编号" />
            <el-table-column  prop="chuangJianRQ" label="创建日期" />
            <el-table-column  prop="suoShuMenDian" label="所属门店" />
            <el-table-column  prop="cangKuMC" label="仓库名称" />
            <el-table-column  prop="cangKuMJ" label="仓库面积㎡" width="100px"/>
<!--            <el-table-column  prop="suoZaiDiQu" label="所在地区" />-->
            <el-table-column  prop="xiangXiDZ" label="详细地址" />
            <el-table-column  prop="fuZeRen" label="负责人" />
            <el-table-column  prop="fuZeRenShouJiHao" label="负责人手机号" />
            <el-table-column  prop="beiZhu" label="备注" />
            <el-table-column  label="是否默认仓库">
                <x-dict-show slot-scope="{row}" :code="row.shiFuMoRenCangKu" dictType="T_YES_OR_NO" />
            </el-table-column>
            <el-table-column width="180" label="启用状态">
                <x-dict-show slot-scope="{row}" :code="row.use" dictType="T_USE" />
            </el-table-column>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button type="primary" size="mini" round v-show="row.use == 'false' "   @click="handleUpdate(row.id)">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round v-show="row.use == 'false' " slot="reference">删除</el-button>
                    </el-popconfirm>

                    <el-popconfirm v-show="row.use != 'true'" title="请确认是否启用这条数据？" @confirm="myHandleEnable(row.id,row.cangKuBH)">
                        <el-button type="success" size="mini" round slot="reference">启用</el-button>
                    </el-popconfirm>
                    <el-popconfirm v-show="row.use == 'true'" title="请确认是否禁用这条数据？" @confirm="myHandleDisable(row.id,row.cangKuBH)">
                        <el-button type="danger" size="mini" round slot="reference">禁用</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/cggl/CangKu";
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/cggl/ckgl/CangKuEdit";
    import Detail from "@/view/cggl/ckgl/CangKuDetail";
    import {checkboxList} from "@/service/mdgl/MenDianXX";
    export default {
        name: "CangKuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                options: [],
                cangKuBH: [],
                menDianOptions: [],
                search: {
                    cangKuBH: '',
                    cangKuMC: '',
                    use: '',
                }
            }
        },
        mounted() {
            this.getCKList()
          /*  this.getMenDianCheckboxList()*/
        },
        methods: {
            // 获取仓库下拉选择列表
            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
            getMenDianCheckboxList(){
                checkboxList().then((response)=>{
                    this.menDianOptions = response.data
                })
            },
            myHandleDisable(id,cangKuBH) {
                this.service.use({id: id,cangKuBH: cangKuBH , use: 'false'}).then((response) => {
                    if (response.code==200) {
                        // 返回成功时显示成功的alert
                        this.$message.success(response.msg);
                    } else {
                        // 返回失败时显示失败的alert
                        this.$message.error("操作失败，请先清空仓库后再操作！");
                    }
                    // 刷新列表
                    this.refresh();
                });
            },
            myHandleEnable(id,cangKuBH) {
                this.service.use({id: id,cangKuBH: cangKuBH , use: 'true'}).then((response) => {
                    if (response.code==200) {
                        // 返回成功时显示成功的alert
                        this.$message.success(response.msg);
                    } else {
                        // 返回失败时显示失败的alert
                        this.$message.error("操作失败，请先清空仓库后再操作！");
                    }
                    // 刷新列表
                    this.refresh();
                });
            },

        }
    }
</script>

<style scoped>
</style>
